import { isbot } from "isbot";
import platform from "platform";
import { isEmpty } from "ramda";

import { fetchConfig } from "./api";
import BrowserSupport from "./components/BrowserSupport";
import { DEFAULT_ENABLED_STATE, WIDGETS, ENV } from "./constants";
import {
  addToDocumentHead,
  initialiseWidgetTracking,
  isWidgetEmbedded,
  setupHoneybadger,
  getEnabledStateStorage,
  isBrowserCompatible,
  isNotWebView,
  shouldPreloadWidget,
} from "./utils";

const initParams = JSON.parse(
  document.currentScript.dataset.initParams || "{}"
);

const Honeybadger = setupHoneybadger();

const embedChatWidget = config => {
  if (isWidgetEmbedded(WIDGETS.chat)) return;

  if (!config || !config.subdomain) return;

  const chatParams = window.NeetoChat || {};
  window.NeetoChat = { ...config, ...chatParams };
  addToDocumentHead("script", { src: CHAT_WIDGET_SRC });
};

const embedReplayWidget = config => {
  if (isWidgetEmbedded(WIDGETS.replay)) return;

  if (!config) return;

  addToDocumentHead("script", {
    onload: () => {
      if (embedNeetoReplayWidget) embedNeetoReplayWidget(config);
    },
    src: REPLAY_WIDGET_SRC,
  });
};

const embedEngageWidget = (config, params) => {
  if (isWidgetEmbedded(WIDGETS.neetoEngage)) return;

  if (!config || !config.subdomain) return;

  const engageParams = params.neetoEngageOptions || {};

  window.neetoEngageConfig = { ...config, ...engageParams };
  addToDocumentHead("script", { src: ENGAGE_WIDGET_SRC });
};

// start preloading scripts for enabled widgets as soon as possible
const initialiseConnections = () => {
  const preloadUrls = [];
  let enabledStates = getEnabledStateStorage();

  if (isEmpty(enabledStates)) enabledStates = DEFAULT_ENABLED_STATE;

  if (shouldPreloadWidget(enabledStates, WIDGETS.chat)) {
    preloadUrls.push(CHAT_WIDGET_SRC);
  }

  if (shouldPreloadWidget(enabledStates, WIDGETS.engage)) {
    preloadUrls.push(ENGAGE_WIDGET_SRC);
  }

  if (shouldPreloadWidget(enabledStates, WIDGETS.replay)) {
    preloadUrls.push(REPLAY_WIDGET_SRC);
  }

  preloadUrls.forEach(url =>
    addToDocumentHead("link", {
      as: "script",
      crossorigin: true,
      rel: "preload",
      href: url,
    })
  );
};

const insertBrowserSupportMessage = () => {
  const browserSupportMessage = new BrowserSupport();
  browserSupportMessage.insert();
};

const embedWidgets = async userParams => {
  const isProduction = process.env.NODE_ENV === ENV.production;

  if (
    (isProduction && isbot(navigator.userAgent)) ||
    window.location.protocol === "file:"
  ) {
    return;
  }

  const platformInfo = platform.parse(navigator.userAgent);
  const isBrowserSupported = isBrowserCompatible(platformInfo);
  const params = { ...initParams, ...userParams };
  const { apiKey } = params;

  if (!apiKey) {
    /* eslint-disable-next-line */
    console.error("Unable to find apiKey to embed neetoWidgets.");

    return;
  }
  try {
    const config = await fetchConfig(params);
    if (!config) return;

    if (isBrowserSupported) {
      initialiseWidgetTracking();
      initialiseConnections();

      if (config[WIDGETS.chat]?.enabled) {
        embedChatWidget(config[WIDGETS.chat], params);
      }

      if (config[WIDGETS.engage]?.enabled) {
        embedEngageWidget(config[WIDGETS.engage], params);
      }

      if (config[WIDGETS.replay]?.enabled) {
        embedReplayWidget(config[WIDGETS.replay], params);
      }
    } else if (
      isNotWebView(navigator.userAgent) &&
      config[WIDGETS.chat]?.enabled
    ) {
      if (document.readyState !== "loading") {
        insertBrowserSupportMessage();
      } else {
        document.onreadystatechange = () => {
          if (document.readyState !== "loading") insertBrowserSupportMessage();
        };
      }
    }
  } catch (err) {
    /* eslint-disable-next-line */
    console.error("Error occurred while embedding widget", err);
    if (Honeybadger) Honeybadger.notify(err);
  }
};

export default embedWidgets;
