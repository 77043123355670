/* eslint-disable @bigbinary/neeto/hard-coded-strings-should-be-localized */
export const EMBEDDED_WIDGET_TRACKING_KEY = "NeetoWidgetEmbeddings";

export const WIDGETS = {
  chat: "chat",
  engage: "engage",
  replay: "replay",
};

export const HONEYBADGER_IGNORABLE_ERRORS = [
  "Failed to fetch",
  "Load failed",
  "ResizeObserver loop limit exceeded",
  "NetworkError when attempting to fetch resource.",
  "Network Error",
  "NetworkError",
  "The operation was aborted.",
  "cancelled",
  "fetch is not defined",
  "Can't find variable: fetch",
];

export const ENV = {
  production: "production",
  staging: "staging",
  development: "development",
};

export const PARSE_STACK_REGEX = /\/([/\w-_.]+\.js):(\d*):(\d*)/;

export const ENABLED_STATE_STORAGE_KEY = "WIDGET_ENABLED_STATES";

export const DEFAULT_ENABLED_STATE = {
  apiKey: { chat: true, engage: true, replay: true },
};

export const DEFAULT_MINIMUM_SUPPORTED_BROWSER_VERSIONS = {
  Electron: 20,
  Chrome: 79,
  MicrosoftEdge: 104,
  Firefox: 91,
  IE: 11,
  Opera: 73,
  Safari: 12.2,
  AndroidBrowser: 4.4,
  OperaMini: 1,
  ChromeMobile: 105,
  FirefoxMobile: 104,
  UCBrowser: 13.4,
  SamsungInternet: 4,
};

export const WEBVIEW_REGEX_PATTERNS = [
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
  // old chrome android webview agent
  "Linux; U; Android",
];
