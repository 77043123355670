import { createRoot } from "react-dom/client";

import "styles/main.scss";

import { BROWSER_SUPPORT_MESSAGE_BINDING_NODE } from "./constants";
import Message from "./Message";

class BrowserSupport {
  insert = () => {
    let bindingNode = document.getElementById(
      BROWSER_SUPPORT_MESSAGE_BINDING_NODE
    );
    if (!bindingNode) {
      bindingNode = document.createElement("div");
      bindingNode.setAttribute("id", BROWSER_SUPPORT_MESSAGE_BINDING_NODE);
      document.body.insertBefore(bindingNode, document.body.firstChild);
    }
    const root = createRoot(bindingNode);
    root.render(<Message />);
  };
}

export default BrowserSupport;
