import platform from "platform";

const Message = () => {
  const platformInfo = platform.parse(navigator.userAgent);
  const content = `You are using an older version of ${platformInfo.name}. Please update your browser to use the chat feature.`;

  return <div className="bw-callout bw-callout--danger">{content}</div>;
};

export default Message;
