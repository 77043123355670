import { ENV, WIDGETS } from "./constants";
import {
  isArrayEmpty,
  getEnabledStateStorage,
  setEnabledStateStorage,
  isWidgetEnabled,
} from "./utils";

export const fetchConfig = async params => {
  const userIdentity = window.NeetoWidgetUserIdentity;
  const isProduction = process.env.NODE_ENV === ENV.production;

  const { apiKey, enableNeetoReplay, enableNeetoChat, enableNeetoEngage } =
    params;

  /* eslint-disable-next-line */
  const data = await fetch(WIDGET_CONFIG_URL, {
    headers: { "Cache-Control": "no-store", "Widget-Api-Key": apiKey },
  }).then(res => {
    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return res.json();
    }

    return {};
  });

  const { subdomain, neeto_widget_tokens: tokens } = data;
  // This environment refers to the environment of the target widget where the common widget should connect to.
  // For production builds, the common widget script connects to 'production' instance of target widgets, and connects to
  // staging instance for other environments.
  const environment = isProduction ? "production" : "staging";
  const suffix = isProduction ? "com" : "net";

  const config = {};
  const enabledState = {};
  const baseConfig = {
    subdomain,
    suffix,
    environment,
    useBetaFeatures: !isProduction,
  };

  if (isArrayEmpty(tokens)) {
    // print error to console for client app reference
    // eslint-disable-next-line no-console
    console.error(
      "No tokens found for your organization matching provided API key"
    );

    return false;
  }

  tokens.forEach(token => {
    const appConfig = { ...baseConfig };
    let isEnabled = false;

    if (token.client_application_name === "Replay") {
      // neetoReplay specific config attributes
      appConfig.replayAuthToken = token.data.replay_auth_token;
      isEnabled = isWidgetEnabled(token, enableNeetoReplay);

      config[WIDGETS.replay] = { ...appConfig, enabled: isEnabled };
    } else if (token.client_application_name === "Chat") {
      // neetoChat specific config attributes
      appConfig.identity = userIdentity;
      appConfig.chatApiKey = token.data.chat_api_key;
      appConfig.neetoChatBaseUrl = `https://${subdomain}.neetochat.${suffix}`;
      appConfig.neetoKbBaseUrl = `https://${subdomain}.neetokb.${suffix}`;
      appConfig.neetoDeskBaseUrl = `https://${subdomain}.neetodesk.${suffix}`;
      isEnabled = isWidgetEnabled(token, enableNeetoChat);

      config[WIDGETS.chat] = { ...appConfig, enabled: isEnabled };
    } else if (token.client_application_name === "Engage") {
      // neetoEngage specific config attributes
      appConfig.productId = token.data.id;
      appConfig.authToken = token.data.auth_token;
      appConfig.baseUrl = `https://${subdomain}.neetoengage.${suffix}`;
      isEnabled = isWidgetEnabled(token, enableNeetoEngage);

      config[WIDGETS.engage] = { ...appConfig, enabled: isEnabled };
    }
    enabledState[token.client_application_name.toLowerCase()] = isEnabled;
  });

  const currentEnabledState = getEnabledStateStorage();
  setEnabledStateStorage({ ...currentEnabledState, [apiKey]: enabledState });

  return config;
};
